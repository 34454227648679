exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-klienta-js": () => import("./../../../src/pages/dla-klienta.js" /* webpackChunkName: "component---src-pages-dla-klienta-js" */),
  "component---src-pages-finansowanie-js": () => import("./../../../src/pages/finansowanie.js" /* webpackChunkName: "component---src-pages-finansowanie-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inwestycja-js": () => import("./../../../src/pages/inwestycja.js" /* webpackChunkName: "component---src-pages-inwestycja-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-mieszkania-js": () => import("./../../../src/pages/mieszkania.js" /* webpackChunkName: "component---src-pages-mieszkania-js" */),
  "component---src-pages-mieszkania-pietra-js": () => import("./../../../src/pages/mieszkania/pietra.js" /* webpackChunkName: "component---src-pages-mieszkania-pietra-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-wykonczenie-js": () => import("./../../../src/pages/wykonczenie.js" /* webpackChunkName: "component---src-pages-wykonczenie-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

